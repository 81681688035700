import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Publications" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-181">
        <div class="format_text">
          <h1>Publications</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_1518996-Writing-300x200.jpeg"
                alt="Pen on legal document"
              />
            </span>
            Larry has written a considerable body of literature on various
            sub-topics of law, corporate finance, and corporate governance. He
            is author of many articles on investment banking and corporate
            finance-related topics for numerous industry trade publications, and
            newspapers such as{" "}
            <em>
              The American Banker, Market Chronicle, Los Angeles Times, The
              Baltimore Sun and The Washington Post
            </em>
            , has written extensively for <em>Mortgage Banking</em> and has
            frequently been quoted in the financial press. A representative
            sample includes:<strong> </strong>
          </p>
          <h2>2025</h2>
          <ul>
            <li>
              The SEC, Fraud, and Crypto Currencies (with Brian Elzweig and Neal
              F. Newman), available at{" "}
              <a href="https://ssrn.com/abstract=4965035">
                https://ssrn.com/abstract=4965035
              </a>
              .
            </li>
            <li>
              Guide to SEC and Business Open Source Corporate Intelligence (with
              Brian Elzweig &amp; Neal F. Newman), available at{" "}
              <a href="https://papers.ssrn.com/sol3/papers.cfm?abstract_id=5002093">
                https://papers.ssrn.com/sol3/papers.cfm?abstract_id=5002093
              </a>
              .
            </li>
            <li>
              The SEC Proposed Cybersecurity Infrastructure Rules and New
              Disclosure Requirements (with Neal F. Newman &amp; Brian Elzweig),
              __ UMKC LAW REVIEW (forthcoming), available at{" "}
              <a href="https://ssrn.com/abstract=4536669">
                https://ssrn.com/abstract=4536669
              </a>
              .
            </li>
            <li>
              Sam Altman, OpenAI, and The Importance of Corporate Governance
              (with Larry D. Foster II) __ CASE WESTERN JOURNAL OF LAW,
              TECHNOLOGY &amp; THE INTERNET (forthcoming), available at{" "}
              <a href="https://ssrn.com/abstract=4679613">
                https://ssrn.com/abstract=4679613
              </a>
              .
            </li>
          </ul>
          <h2>2024</h2>
          <ul>
            <li>
              Financial Institution D&amp;O Liability After Caremark and
              McDonald’s (with H. Justin Pace) 76 RUTGERS UNIVERSITY LAW REVIEW
              449 (2024),{" "}
              <a href="http://ssrn.com/abstract=4566471">
                http://ssrn.com/abstract=4566471
              </a>
              .
            </li>
            <li>
              Capital Formation, The SEC, and Accredited Investors (with Neal F.
              Newman &amp; Brian Elzweig), __ UNIVERSITY OF PENNSYLVANIA JOURNAL
              OF BUSINESS LAW (forthcoming), available at{" "}
              <a href="https://ssrn.com/abstract=4771089">
                https://ssrn.com/abstract=4771089
              </a>
              .
            </li>
            <li>
              Understanding Cyber Risk: Unpacking and Responding to Cyber
              Threats Facing the Public and Private Sectors (with Scott
              Shackelford, Brian Elzweig, and Peter C. Ormerod) 78 UNIVERSITY OF
              MIAMI LAW REVIEW 840 (2024), {" "}
              <a href="https://ssrn.com/abstract=4262971">
                https://ssrn.com/abstract=4262971
              </a>
              .
            </li>
            <li>
              How We Learned to Stop Worrying and Love AI: Analyzing the Rapid
              Evolution of Generative Pre-Trained Transformer (GPT) and its
              Impacts on Law, Business, and Society (with Scott Shackelford and
              W. Gregory Voss) 34(1) ALBANY LAW JOURNAL OF SCIENCE &amp;
              TECHNOLOGY (forthcoming),{" "}
              <a href="http://ssrn.com/abstract=4516154">
                http://ssrn.com/abstract=4516154
              </a>
              .
            </li>
            <li>
              Personal Ethics, Subprime Mortgages and the U.S. Financial
              Collapse of 2007-09: A Post- Chevron “Fail Safe” Proposal, __ REAL
              ESTATE LAW JOURNAL (forthcoming), available at{" "}
              <a href="https://ssrn.com/abstract=2502124">
                https://ssrn.com/abstract=2502124
              </a>
              .
            </li>
            <li>
              Why Study Ethics? (with Lois A. Blyden, Nanci Carr, Jehan
              El-Jourbagy, Larry D. Foster II, Chelsea Green, Bruce Klaw, Robert
              W. McGee, Santiago Mejia, Karen, Diane Meyers, Eric Sader, David
              D. Schein &amp; Cari Sheehan, 16 SOUTHERN JOURNAL OF BUSINESS
              &amp; ETHICS 35 (2024), available at{" "}
              <a href="https://ssrn.com/abstract=4497895">
                https://ssrn.com/abstract=4497895
              </a>
              .
            </li>
            <li>
              Alternative Dispute Resolution (ADR) in Brief (with Larry D.
              Foster II, Michael Conklin, David Orozco, &amp; Carrie Shu Shang)
              __ ATLANTIC LAW JOURNAL (conditional acceptance),{" "}
              <a href="http://ssrn.com/abstract=4383603">
                http://ssrn.com/abstract=4383603
              </a>
              .
            </li>
            <li>
              Timely Thoughts About U.S. Real Estate Markets (with Sanzid A. Haq
              &amp; Larry D. Foster II), 53 REAL ESTATE LAW JOURNAL 74 (Fall
              2024), available at{" "}
              <a href="http://ssrn.com/abstract=4534533">
                http://ssrn.com/abstract=4534533
              </a>
              .
            </li>
            <li>
              The Internet of Things (IoT) in a Post-Pandemic World, (with
              Mohammed T. Hussein) 9 JOURNAL OF LAW &amp; CYBER WARFARE (2024),{" "}
              <a href="http://ssrn.com/abstract=4149477">
                http://ssrn.com/abstract=4149477
              </a>
              ;{" "}
              <a href="https://www.amazon.com/dp/B0D67X286C">
                https://www.amazon.com/dp
              </a>
              .
            </li>
          </ul>
          <h2>2023</h2>
          <ul>
            <li>
              When Does A Nonfungible Token (NFT) Become A Security?, (with
              Brian Elzweig) 39 GEORGIA STATE UNIVERSITY LAW REVIEW 295 (2023),{" "}
              <a href="http://ssrn.com/abstract=4055585">
                http://ssrn.com/abstract=4055585
              </a>
              .
            </li>
            <li>
              The FTX Crypto Debacle: Largest Fraud Since Madoff?, 54 UNIVERSITY
              OF MEMPHIS LAW REVIEW (with Larry D. Foster II) 289 (Winter 2023),{" "}
              <a href="http://ssrn.com/abstract=4290093">
                http://ssrn.com/abstract=4290093
              </a>
              .
            </li>
            <li>
              Climate Change and Caremark Doctrine, Imperfect Together (with H.
              Justin Pace), 25 UNIVERSITY OF PENNSYLVANIA JOURNAL OF BUSINESS
              LAW 777 (2023), available at{" "}
              <a href="http://ssrn.com/abstract=4202412">
                http://ssrn.com/abstract=4202412
              </a>
              .
            </li>
            <li>
              Crisis at the Audit Committee: Challenges of a Post-Pandemic World
              (with Hon. Bernice Donald, Brian Elzweig, Neal F. Newman &amp; H.
              Justin Pace), 42 REVIEW OF BANKING &amp; FINANCIAL LAW [Boston
              University] (forthcoming),{" "}
              <a href="http://ssrn.com/abstract=4240080">
                http://ssrn.com/abstract=4240080
              </a>
              .
            </li>
            <li>
              Legal Recourse for Victims of Blockchain and Cyber Breach Attacks
              (with Michael J. Conklin &amp; Brian Elzweig) 23(2) U.C. DAVIS
              BUSINESS LAW JOURNAL 135 (2022-2023),{" "}
              <a href="http://ssrn.com/abstract=4251666">
                http://ssrn.com/abstract=4251666
              </a>
              .
            </li>
            <li>
              Technology Employment, Information and Communications in the
              Digital Age (with Mohammed T. Hussein &amp; Reginald Holloway),
              103 JOURNAL OF THE U.S. PATENT &amp; TRADEMARK OFFICE SOCIETY, 101
              (Jan. 2023),{" "}
              <a href="http://ssrn.com/abstract=3762273">
                http://ssrn.com/abstract=3762273
              </a>
              .
            </li>
            <li>
              Visual Art, Galleries, Collectibles, and NFTs, In The Cambridge
              Handbook on Law and Policy for NFTs (Nizan Geslevich Packin, Ed.),
              (2023),{" "}
              <a href="https://ssrn.com/abstract=4311387">
                https://ssrn.com/abstract=4311387
              </a>
              .
            </li>
          </ul>
          <h2>2022</h2>
          <ul>
            <li>
              Tik Tok! TikTok: Escalating Tension Between U.S. Privacy Rights
              and National Security Vulnerabilities, available at{" "}
              <a href="http://ssrn.com/abstract=4163203">
                http://ssrn.com/abstract=4163203
              </a>
              .
            </li>
            <li>
              Mission Critical: Caremark. Blue Bell, and Director Responsibility
              for Cybersecurity Governance (with H. Justin Pace) __ Wisconsin
              Law Review (forthcoming) (2021 SEALSB Best Proceedings Paper
              Award), available at{" "}
              <a href="http://ssrn.com/abstract=3938128">
                http://ssrn.com/abstract=3938128
              </a>
              .
            </li>
            <li>
              Democracy At Risk: Domestic Terrorism and Attack on the U.S.
              Capitol, 45 Seattle University Law Review (forthcoming) available
              at{" "}
              <a href="http://ssrn.com/abstract=3808365">
                http://ssrn.com/abstract=3808365
              </a>
              .
            </li>
            <li>
              Virtual Art and Non-fungible Tokens, 50 Hofstra Law Review 361
              (2022), available at{" "}
              <a href="http://ssrn.com/abstract=3814087">
                http://ssrn.com/abstract=3814087
              </a>
              .
            </li>
            <li>
              Special Purpose Acquisition Companies (SPACs) and the SEC, (with
              Neal Newman), 24(3) University of Pennsylvania Journal of Business
              Law (forthcoming 2022), available at{" "}
              <a href="http://ssrn.com/abstract=3905372">
                http://ssrn.com/abstract=3905372
              </a>
              .
            </li>
            <li>
              Corporate Directors: Who They Are, What They Do, Cyber and Other
              Contemporary Challenges (with Seletha Butler, Frederick R. Chang,
              Michele Hooper, Ron McCray and Ruth Simmons), 70 Buffalo Law
              Review 459 (2022), available at{" "}
              <a href="http://ssrn.com/abstract=3792382">
                http://ssrn.com/abstract=3792382
              </a>
              .
            </li>
            <li>
              When Does A Nonfungible Token (NFT) Become A Security?, __ Georgia
              State University Law Review (with Brian Elzweig) (forthcoming),
              available at{" "}
              <a href="http://ssrn.com/abstract=4055585">
                http://ssrn.com/abstract=4055585
              </a>
              .
            </li>
            <li>
              The Environmental, Social and Governance (ESG) Debate Emerges from
              the Soil of Climate Denial, (with Neal Newman), 53 University of
              Memphis Law Review, available at{" "}
              <a href="http://ssrn.com/abstract=3939898">
                http://ssrn.com/abstract=3939898
              </a>
              .
            </li>
            <li>
              Improving University Scholarship Rankings: Lessons Learned from
              the Movie Moneyball (with Munir Quddus), UNT DALLAS Law Review: On
              The Cusp (forthcoming), available at{" "}
              <a href="http://ssrn.com/abstract=3677556">
                http://ssrn.com/abstract=3677556
              </a>
              .
            </li>
            <li>
              Contracts in Brief (with Larry D. Foster II, Lora J. Koretz,
              Anthony L. McMullen, H. Justin Pace, and Michelle A. Romero),
              available at{" "}
              <a href="http://ssrn.com/abstract=3571997">
                http://ssrn.com/abstract=3571997
              </a>
              .
            </li>
            <li>
              A Proposed SEC Cyber Data Disclosure Advisory Commission (with
              Neal Newman) Securities Regulation Law Journal (forthcoming 2022),
              available at{" "}
              <a href="http://ssrn.com/abstract=4097138">
                http://ssrn.com/abstract=4097138
              </a>
              .
            </li>
            <li>
              The Internet of Things (IoT) in a Post-Pandemic World (with
              Mohammed T. Hussein) available at{" "}
              <a href="http://ssrn.com/abstract=4149477">
                http://ssrn.com/abstract=4149477
              </a>
              .
            </li>
          </ul>
          <h2>2021</h2>
          <ul>
            <li>
              The Demographics of Death: An Early Look at Covid-19, Cultural and
              Racial Bias in America (with U.S. Congresswoman Eddie Bernice
              Johnson), 48 Hastings Constitutional Law Quarterly 357 (2021),
              available at{" "}
              <a href="http://ssrn.com/abstract=3677607">
                http://ssrn.com/abstract=3677607
              </a>
              .{" "}
            </li>
            <li>
              Rapid Technological Change and U.S. Entrepreneurial Risk in
              International Markets: Focus on Data Security, Information
              Privacy, Bribery and Corruption, 49 Capital U. Law Review 67
              (2021), at{" "}
              <a href="https://ssrn.com/abstract=2912072">
                https://ssrn.com/abstract=2912072
              </a>
              .{" "}
            </li>
            <li>
              Cannabis At the Crossroads: Regulation, A Transdisciplinary
              Analysis and Policy Prescription (with Donald Mayer, Paul Seaborn,
              Adam Sulkowski & Robert T. Luttrell), 46 Oklahoma City University
              Law Review 125 (2021), available at{" "}
              <a href="http://ssrn.com/abstract=3541229">
                http://ssrn.com/abstract=3541229
              </a>
              .{" "}
            </li>
            <li>
              Posted: No Phishing (with Mohammed T. Hussein, Emmanuel U. Opara,
              Mason J. Molesky and Shahedur Rahman), 8 Emory Corporate
              Governance and Accountability Review 39 (2021), available at{" "}
              <a href="http://ssrn.com/abstract=3549992">
                http://ssrn.com/abstract=3549992
              </a>
              .{" "}
            </li>
            <li>
              Governance of the Facebook Privacy Crisis, Reprint of 2020
              Pittsburgh Journal of Technology Law & Policy article In
              Entertainment, Publishing and the Arts Handbook 2020 (Thompson
              Reuters-West) (“Judged one of the best law review articles related
              to entertainment, publishing and/or the arts published within the
              last year” & selected for reprint).
            </li>
            <li>
              David Thaw, Charlotte Tschider, Gus Hurwitz, and Derek Bambauer,
              Cybersecurity: An Interdisciplinary Problem (West Publishing,
              2021) (excerpting from Corporate Directors’ and Officers’
              Cybersecurity Standard of Care: The Yahoo Data Breach, 66 American
              University Law Review 1233–67 (2017).{" "}
            </li>
            <li>
              Securities Law: Overview and Contemporary Issues (with Neal
              Newman) 16 Ohio State Business Law Journal (2021), available at{" "}
              <a href="http://ssrn.com/abstract=3790804">
                http://ssrn.com/abstract=3790804
              </a>
              .{" "}
            </li>
            <li>
              Special Purpose Acquisition Companies (SPACs) and the SEC, (with
              Neal Newman), 24(3) University of Pennsylvania Journal of Business
              Law (forthcoming),{" "}
              <a href="http://ssrn.com/abstract=3905372">
                http://ssrn.com/abstract=3905372
              </a>
              .
            </li>
            <li>
              Teaching Ethics and Values in an Age of Rapid Technological Change
              (with Kenneth J. Sanney, Eric D. Yordy, Tammy W. Cowart & Destynie
              Sewell), 17 Rutgers Business Law Review 17 (2021),{" "}
              <a href="http://ssrn.com/abstract=3102552">
                http://ssrn.com/abstract=3102552
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2020</h2>
          <ul>
            <li>
              Governance of the Facebook Privacy Crisis, 20(1) Pittsburgh
              Journal of Technology Law & Policy 41 (2020), available at{" "}
              <a href="http://ssrn.com/abstract=3363002">
                http://ssrn.com/abstract=3363002
              </a>
              .{" "}
            </li>
            <li>
              Governance of The Internet of Things (IoT) (with Mohammed T.
              Hussein, Louis Ngamassi & Mason Molesky), 60(3) Jurimetrics 315
              (Spring 2020), at{" "}
              <a href="http://ssrn.com/abstract=3443973">
                http://ssrn.com/abstract=3443973
              </a>
              .{" "}
            </li>
            <li>
              The Dark Web and Employer Liability (with David D. Schein), 18(1)
              Colorado Technology Law Journal 49 (2020), available at{" "}
              <a href="http://ssrn.com/abstract=3251479">
                http://ssrn.com/abstract=3251479
              </a>
              .{" "}
            </li>
            <li>
              Lessons About Franchise Risk From YUM! Brands and Schlotsky’s
              (with Robert W. Emerson), 24 Lewis & Clark Law Review 997 (2020)
              available at{" "}
              <a href="http://ssrn.com/abstract=3442905">
                http://ssrn.com/abstract=3442905
              </a>
              .{" "}
            </li>
            <li>
              The Importance of Truth Telling and Trust, (with Kenneth J.
              Sanney, Eric D. Yordy, Tammy W. Cowart, and Destynie Sewell),
              37(1) Journal of Legal Studies Education 7 (Winter 2020) (peer
              reviewed), available at{" "}
              <a href="http://ssrn.com/abstract=3430854">
                http://ssrn.com/abstract=3430854
              </a>
              .
            </li>
            <li>
              Impeachment, Donald Trump and The Attempted Extortion of Ukraine,
              40(2) Pace Law Review 141 (2020), available at{" "}
              <a href="http://ssrn.com/abstract=3518082">
                http://ssrn.com/abstract=3518082
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2019</h2>
          <ul>
            <li>
              WannaCry, Ransomware, and the Emerging Threat to Corporations,
              (with Peter C. Ormerod), 86(2) Tennessee Law Review 503 (2019),{" "}
              <a href="http://ssrn.com/abstract=3238293">
                http://ssrn.com/abstract=3238293
              </a>
              .{" "}
            </li>
            <li>
              A Primer for Blockchain (with Mason J. Molesky), 88(2) UMKC Law
              Review 239 (2019), arXiv:1904.03254, available at{" "}
              <a href="https://ssrn.com/abstract=3324660">
                https://ssrn.com/abstract=3324660
              </a>
              .{" "}
            </li>
            <li>
              The Twenty-Fifth Amendment: Incapacity and Ability to Discharge
              the Powers and Duties of Office?, 67(3) Cleveland State Law Review
              373 (2019), available at{" "}
              <a href="https://ssrn.com/abstract=3262019">
                https://ssrn.com/abstract=3262019
              </a>
              .{" "}
            </li>
            <li>
              Presidential Impeachment: A Contemporary Analysis, 44(3)
              University of Dayton Law Review 529 (2019), available at{" "}
              <a href="https://ssrn.com/abstract=3290722">
                https://ssrn.com/abstract=3290722
              </a>
              .{" "}
            </li>
            <li>
              IRAC! IRAC! IRAC!: How To Brief Any Legal Issue, (with Cathy L.
              Taylor, Janet Ford, Lora J. Koretz, Anthony McMullen & Erin Isom),
              29(2) Southern Law Journal 317 (Fall 2019),{" "}
              <a href="http://ssrn.com/abstract=2827285">
                http://ssrn.com/abstract=2827285
              </a>
              .{" "}
            </li>
            <li>
              Crowdfunding, Entrepreneurship, and Start-Up Finance (with Oliver
              W. Aho), Entrepreneur & Innovation Exchange (EiX.org) (2019) at{" "}
              <a href="http://ssrn.com/abstract=3251538">
                http://ssrn.com/abstract=3251538
              </a>
              . Winner of the Schultze Publication Award, 2019.
            </li>
          </ul>
          <h2>2018</h2>
          <ul>
            <li>
              Industrial Cyber Vulnerabilities: Lessons from Stuxnet and the
              Internet of Things, (with Peter C. Ormerod), 72 University of
              Miami Law Review 761 (2018), available at{" "}
              <a href="http://ssrn.com/abstract=2982629">
                http://ssrn.com/abstract=2982629
              </a>
              .{" "}
            </li>
            <li>
              Bitcoin, Virtual Currencies and the Struggle of Law and Regulation
              to Keep Pace, 102 Marquette Law Review 447 (2018), available at{" "}
              <a href="https://ssrn.com/abstract=3182867">
                https://ssrn.com/abstract=3182867
              </a>
              .{" "}
            </li>
            <li>
              Nonprofit Governance: The Basics (with Janet Ford), 52 Akron Law
              Review 971 (2018), available at{" "}
              <a href="https://ssrn.com/abstract=3133818">
                https://ssrn.com/abstract=3133818
              </a>
              .
            </li>
            <li>
              The Value of Legal Writing, Law Review, and Publication, 51
              Indiana Law Review 693 (2018), available at{" "}
              <a href="http://ssrn.com/abstract=2501834">
                http://ssrn.com/abstract=2501834
              </a>
              .{" "}
            </li>
            <li>
              Grab ‘Em By the Emoluments: The Crumbling Ethical Foundation of
              Donald Trump’s Presidency, 17(2) Connecticut Public Interest Law
              Journal 169 (2018), available at{" "}
              <a href="http://ssrn.com/abstract=2999769">
                http://ssrn.com/abstract=2999769
              </a>
              .{" "}
            </li>
            <li>
              How Google Perceives Customer Privacy, Cyber, E-Commerce,
              Political and Regulatory Compliance Risks, 10 William and Mary
              Business Law Review 1 (2018), available at{" "}
              <a href="https://ssrn.com/abstract=3067298">
                https://ssrn.com/abstract=3067298
              </a>
              .{" "}
            </li>
            <li>
              A Descriptive Analysis of the Fourth Amendment and the Third-Party
              Doctrine in the Digital Age!, (with Peter C. Ormerod), 28 Albany
              Law Journal of Science & Technology 73 (2018), available at{" "}
              <a href="https://ssrn.com/abstract=3005714">
                https://ssrn.com/abstract=3005714
              </a>
              .
            </li>
            <li>
              Bribery and Corruption: The COSO Framework, FCPA, and U.K. Bribery
              Act, (with Joanna Kimbell) 30(3) Florida Journal of International
              Law 191 (2018), available at{" "}
              <a href="http://ssrn.com/abstract=3239193">
                http://ssrn.com/abstract=3239193
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2017</h2>
          <ul>
            <li>
              Bitcoin Versus Regulated Payment Systems: What Gives? (with Alvin
              C. Harrell), 38 Cardozo Law Review, 1041 (2017), available at{" "}
              <a href="http://ssrn.com/abstract=2730983">
                http://ssrn.com/abstract=2730983
              </a>
              .{" "}
            </li>
            <li>
              Corporate Directors' and Officers' Cybersecurity Standard of Care:
              The Yahoo Data Breach, (with Peter C. Ormerod), 66 American
              University Law Review 1231 (2017), available at{" "}
              <a href="http://ssrn.com/abstract=2883607">
                http://ssrn.com/abstract=2883607
              </a>
              .
            </li>
            <li>
              Following the Money: Lessons from the “Panama Papers,” Part 1: Tip
              of the Iceberg, 121 Penn State Law Review 807 (2017), available at{" "}
              <a href="http://ssrn.com/abstract=2783503">
                http://ssrn.com/abstract=2783503
              </a>
              .
            </li>
            <li>
              The Board’s Responsibility for Crisis Governance, 13 Hastings
              Business Law Journal 275 (2017), available at{" "}
              <a href="http://ssrn.com/abstract=2623219">
                http://ssrn.com/abstract=2623219
              </a>
              .{" "}
            </li>
            <li>
              How Law Operates in a Wired Global Society: Cyber and E-Commerce
              Risk, Proceeds of the Korea Legislation Research Institute (KLRI),
              2017 Legal Scholar Roundtable, Seoul, Korea, 21-22 Sept., 2017,
              available at{" "}
              <a href="https://ssrn.com/abstract=3033776">
                https://ssrn.com/abstract=3033776
              </a>
              .
            </li>
          </ul>
          <h2>2016</h2>
          <ul>
            <li>
              Is Cyberattack The Next Pearl Harbor?, 18 North Carolina Journal
              of Law & Technology 232 (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2711059">
                http://ssrn.com/abstract=2711059
              </a>
              .{" "}
            </li>
            <li>
              Managing Cyberthreat, 33(2) Santa Clara High Tech. L.J. 230
              (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2534119">
                http://ssrn.com/abstract=2534119
              </a>
              .{" "}
            </li>
            <li>
              Ethical Culture and Legal Liability: The GM Switch Crisis and
              Lessons in Governance, (with Marianne M. Jennings), 22 Boston
              University Journal of Science & Technology Law 187 (2016),{" "}
              available at{" "}
              <a href="http://ssrn.com/abstract=2691536">
                http://ssrn.com/abstract=2691536
              </a>
            </li>
            .
            <li>
              E-Commerce, Cyber and Electronic Payment System Risks: Lessons
              from PayPal, 17 U.C. Davis Business Law Journal 261 (Spring 2016),
              at{" "}
              <a href="http://www.ssrn.com/abstract=2314119">
                http://www.ssrn.com/abstract=2314119
              </a>
              .{" "}
            </li>
            <li>
              Is Disruptive Blockchain Technology the Future of Financial
              Services?, 69 The Consumer Finance Law Quarterly Report 232
              (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2786186">
                http://ssrn.com/abstract=2786186
              </a>
              .{" "}
            </li>
            <li>
              Who Sits on Texas Corporate Boards? Texas Corporate Directors: Who
              They Are and What They Do, 16 Houston Business and Tax Law Journal
              44 (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2493569">
                http://ssrn.com/abstract=2493569
              </a>
              .{" "}
            </li>
            <li>
              Some Key Things U.S. Entrepreneurs Need to Know About The Law and
              Lawyers (with Anthony “Tony” Luppino & Malika S. Simmons), 46
              Texas Journal of Business Law 155 (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2606808">
                http://ssrn.com/abstract=2606808
              </a>
              .
            </li>
            <li>
              Congressional Cybersecurity Oversight: Who’s Who & How It Works, 5
              Journal of Law and Cyber Warfare 147 (2016), available at{" "}
              <a href="http://ssrn.com/abstract=2638448">
                http://ssrn.com/abstract=2638448
              </a>
              .
            </li>
          </ul>
          <h2>2015</h2>
          <ul>
            <li>
              Cybersecurity: What About U.S. Policy?, 2015 University of
              Illinois Journal of Law, Technology & Policy 341 (2015), available
              at{" "}
              <a href="http://ssrn.com/abstract=2548561">
                http://ssrn.com/abstract=2548561
              </a>
              .{" "}
            </li>
            <li>
              Corporate Boardroom Diversity: Why Are We Still Talking About
              This?, 17 The Scholar: St. Mary’s Law Review on Race and Social
              Justice 219 (2015), available at{" "}
              <a href="http://www.ssrn.com/abstract=2047750">
                http://www.ssrn.com/abstract=2047750
              </a>
              .{" "}
            </li>
            <li>
              Present at the Creation: Reflections on the Early Years of the
              National Association of Corporate Directors, 17 Duquesne Business
              Law Journal 1 (2015), available at{" "}
              <a href="http://ssrn.com/abstract=2296427">
                http://ssrn.com/abstract=2296427
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2014</h2>
          <ul>
            <li>
              Virtual Currencies: Bitcoin & What Now After Liberty Reserve, Silk
              Road, and Mt. Gox?, 20 Richmond Journal of Law & Technology 13
              (2014), available at{" "}
              <a href="http://www.ssrn.com/abstract=2393537">
                http://www.ssrn.com/abstract=2393537
              </a>
              .{" "}
            </li>
            <li>
              The SEC & The Internet: Regulating the Web of Deceit, (with George
              P. Michaely, Jr.), 68 The Consumer Finance Law Quarterly Report
              262 (2014), available at{" "}
              <a href="http://www.ssrn.com/abstract=1951148">
                http://www.ssrn.com/abstract=1951148
              </a>
              .{" "}
            </li>
            <li>
              Remembering George Michaely, (with Hon. Stanley Sporkin & John A.
              Dudley), 68 The Consumer Finance Law Quarterly Report 262 (2014),
              available at{" "}
              <a href="http://ssrn.com/abstract=2431375">
                http://ssrn.com/abstract=2431375
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2013</h2>
          <ul>
            <li>
              Lawyers, Guns and Money – The Bribery Problem and U.K. Bribery
              Act, (with Kara Altenbaumer-Price), 47 The International Lawyer
              481 (2013), available at{" "}
              <a href="http://www.ssrn.com/abstract=2276738">
                http://www.ssrn.com/abstract=2276738
              </a>
              . The International Lawyer (ABA-sponsored) is the most
              widely-distributed U.S. international law review, with
              subscriptions of over twenty thousand readers (2nd only to The
              Harvard Law Review) in ninety+ countries.{" "}
            </li>
            <li>
              Who Qualifies as an Audit Committee Financial Expert Under SEC
              Regulations and NYSE Rules?, 11 DePaul Business & Commercial Law
              Journal 205 (2013), available at{" "}
              <a href="http://www.ssrn.com/abstract=2137747">
                http://www.ssrn.com/abstract=2137747
              </a>
              .{" "}
            </li>
            <li>
              Foreign Corrupt Practices Act: An Update on Enforcement and SEC
              and DOJ Guidance, (with Kara Altenbaumer-Price), 41 Securities
              Regulation Law Journal 241 (2013), available at{" "}
              <a href="http://ssrn.com/abstract=2293382">
                http://ssrn.com/abstract=2293382
              </a>
              .{" "}
            </li>
            <li>
              Corporate Information Technology Governance Under Fire, (with
              Jason Triche & James C. Wetherbe), 8 Journal of Strategic &
              International Studies 105 (2013), available at{" "}
              <a href="http://ssrn.com/abstract=2314119">
                http://ssrn.com/abstract=2314119
              </a>
              .{" "}
            </li>
            <li>
              Corporate Director Selection and Recruitment: A Matrix, The
              Conference Board, Director Notes (May 2013), available at{" "}
              <a href="http://www.ssrn.com/abstract=2269404">
                http://www.ssrn.com/abstract=2269404
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2012</h2>
          <ul>
            <li>
              D&O Insurance: A Primer, (with Kara Altenbaumer-Price), 1 The
              American University Business Law Review 337 (2012), available at{" "}
              <a href="http://www.ssrn.com/abstract=1998080">
                http://www.ssrn.com/abstract=1998080
              </a>
              .{" "}
            </li>
            <li>
              American Entrepreneur in China: Potholes on the Silk Road to
              Prosperity, 12 Wake Forest Journal of Business & Intellectual
              Property Law 427 (2012), available at{" "}
              <a href="http://www.ssrn.com/abstract=1995076">
                http://www.ssrn.com/abstract=1995076
              </a>
              .{" "}
            </li>
            <li>
              The Matrix: The Board’s Responsibility for Director Selection and
              Recruitment, 11 Florida State University Business Review 75
              (2012), available at{" "}
              <a href="http://www.ssrn.com/abstract=1998489">
                http://www.ssrn.com/abstract=1998489
              </a>
              .{" "}
            </li>
          </ul>
          <h2>2011</h2>
          <ul>
            <li>
              The Foreign Corrupt Practices Act: Minefield for Directors (with
              Kara Altenbaumer-Price), 6 Virginia Law & Business Review 145
              (2011), at{" "}
              <a href="http://www.ssrn.com/abstract=1930190">
                http://www.ssrn.com/abstract=1930190
              </a>
              .{" "}
            </li>
            <li>
              The Board’s Responsibility for Information Technology Governance,
              (with Kara Altenbaumer-Price), 29 John Marshall Journal of
              Computer & Information Law, 313 (2011), available at{" "}
              <a href="http://www.ssrn.com/abstract=1947283">
                http://www.ssrn.com/abstract=1947283
              </a>
              .{" "}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
